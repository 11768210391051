import React from "react";
import Modal from "../../../components/Modals";
import ClipLoader from "react-spinners/ClipLoader";

const ModalRemove = ({ actionModal, openModal, handleRemove, id, loading }) => (
    <Modal
        onClose={actionModal}
        size="xs:w-11/12 lg:w-4/12"
        show={openModal}
        title={
            <div className="flex items-center">
                <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                    Remover contrato de especificação operacional
                </h5>
            </div>
        }>
        <p className="text-center">Tem certeza que deseja realizar esta ação?</p>
        <button
            className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md flex gap-4 items-center float-right mt-10"
            onClick={() => {
                handleRemove(id);
            }}>
            {loading && <ClipLoader size="16" color="white" />}
            {loading ? "Removendo" : "Remover"}
        </button>
    </Modal>
);

export default ModalRemove;
